import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import Title from "components/Title";
import Image from "next/image";
import htmlContent from "helpers/htmlContent";

export default function MainSocialInformation({t}) {
	return (
		<Wrapper>
			<CenterBlock>
				<div className="list">
					{
						t.list.map((item, index) => {
							return (
								<div className="item" key={index}>
									<div className="image_mobile">
										<Image src={item.image} width={item.width} height={item.height} priority={true} alt={item.title}/>
									</div>
									<Title><h2>{item.title}</h2></Title>
									<div className="content_box">
										<div className="content">
											<img src="/pic/twitter-social.svg" alt={item.title}/>
											<p>{htmlContent(item.description)}</p>
											<div className="link">
												<a href={item.link} target="_blank" rel="noreferrer">
													Twitter post
													<img src="/pic/link-icon.svg" alt="Read more"/>
												</a>
											</div>
										</div>
										<div className="image">
											<Image src={item.image} width={item.width} height={item.height} priority={true} alt={item.title}/>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
				<div className="description_block">
					<div className="description">
						<div className="value">🔥40%</div>
						<div className="line"/>
						<div className="value_description">{t.value_description}</div>
					</div>
					<div className="blur"><img src="/pic/percent-blur.svg" alt="40%"/></div>
				</div>
			</CenterBlock>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	margin-top: 120px;
	@media only screen and (max-width: 600px) {
		margin-top: 70px;
	}
	.image{
		line-height: 0;
		@media only screen and (max-width: 820px) {
			display: none;
		}
	}
	.title{
		h2{
			@media only screen and (max-width: 600px) {
				font-size: 30px;
				line-height: 140%;
			}
		}
	}
	.image_mobile{
		display: none;
		align-items: center;
		justify-content: center;
		text-align: center;
		line-height: 0;
		width: 130px;
		height: 130px;
		background: #C4C4C4;
		border-radius: 50%;
		overflow: hidden;
		@media only screen and (max-width: 820px) {
			display: flex;
		}
		@media only screen and (max-width: 600px) {
			padding: 17px;
		}
	}
	.blur{
		display: flex;
		justify-content: center;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: -170px;
	}
	.description_block{
		position: relative;
	}
	.description{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 40px;
		position: relative;
		z-index: 10;
		@media only screen and (max-width: 600px) {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}
		.value{
			font-size: 60px;
			line-height: 140%;
			font-weight: bold;
			font-family: "Halvar Breitschrift", sans-serif;
			@media only screen and (max-width: 600px) {
				font-size: 40px;
				line-height: 140%;
				text-align: center;
				width: 100%;
			}
		}
		.line{
			border-right: 1px solid #AF0023;
			height: 64px;
			margin: 0 20px;
			@media only screen and (max-width: 600px) {
				display: none;
			}
		}
		.value_description{
			width: 327px;
			font-size: 24px;
			line-height: 140%;
			color: #D9D2CF;
			@media only screen and (max-width: 600px) {
				font-size: 18px;
				line-height: 140%;
				text-align: center;
			}
		}
	}
	.list{
		position: relative;
		z-index: 10;
	}
	.item{
		background: #131323;
		border-radius: 36px;
		margin-bottom: 60px;
		padding: 60px 80px;
		@media only screen and (max-width: 820px) {
			padding: 60px 80px;
		}
		@media only screen and (max-width: 600px) {
			padding: 30px 24px;
		}
		&:first-child{
			padding: 60px 80px 0 80px;
			@media only screen and (max-width: 820px) {
				padding: 60px 80px;
			}
			@media only screen and (max-width: 600px) {
				padding: 30px 24px;
			}
		}
		.content{
			width: calc(100% - 300px);
			padding-right: 70px;
			@media only screen and (max-width: 820px) {
				padding-right: 0;
				width: 100%;
			}
		}
		.content_box{
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			@media only screen and (max-width: 820px) {
				flex-direction: column;
			}
			p{
				font-size: 24px;
				line-height: 140%;
				color: #CFCFD2;
				margin-top: 20px;
				@media only screen and (max-width: 600px) {
					font-size: 18px;
					line-height: 140%;
				}
				a{
					color: #007BBB;
				}
			}
		}
		&:nth-child(odd){
			.content_box{
				flex-direction: row-reverse;
				@media only screen and (max-width: 820px) {
					flex-direction: column;
				}
			}
			.content{
				padding-right: 0;
				padding-left: 70px;
				@media only screen and (max-width: 820px) {
					padding-left: 0;
				}
			}
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
	.title{
		@media only screen and (max-width: 820px) {
			margin-top: 40px;
		}
	}
	.link{
		margin-top: 50px;
		a{
			font-size: 24px;
			line-height: 140%;
			color: #0092DB;
			display: flex;
			align-items: center;
			img{
				margin-left: 8px;
			}
		}
	}
`
