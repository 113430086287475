import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import Title from "components/Title";
import LiveAnimation from "components/LiveAnimation";
import Link from "next/link";
import numberFormat from "helpers/numberFormat";
import Image from "next/image";

export default function MainCollections({t}) {
	return (
		<Wrapper>
			<CenterBlock>
				<div className="label"><img src="/pic/collections-title.svg" alt="Collections"/></div>
				<div className="list">
					<div className="item">
						<div className="image">
							<Image src="/pic/collections-pic-1.jpg" width={401} height={401} priority={true} alt="BAYCT"/>
						</div>
						<Title><h2>{t.collection1_title} <img src="/pic/v-icon.svg" alt={t.collection1_title}/></h2></Title>
						<p className="description">{t.collection1_description}</p>
						<div className="line sold">{t.sold_out}</div>
						<div className="total">{numberFormat(10000)}</div>
						<div className="total_description">{t.apes}</div>
						<div className="link">
							<a href="https://apenft.io/#/collection/TWi53fvZgTsW8tvAQmYVeThnBeyJqEfJhQ" target="_blank" rel="noreferrer">
								{t.view_on_apenft}
								<img src="/pic/link-icon-2.svg" alt="View on APENFT BAYCT"/>
							</a>
						</div>
					</div>
					<div className="item">
						<div className="image">
							<Image src="/pic/collections-pic-2.jpg" width={401} height={401} priority={true} alt="MAYCT"/>
						</div>
						<Title><h2>{t.collection2_title} <img src="/pic/v-icon.svg" alt={t.collection2_title}/></h2></Title>
						<p className="description">{t.collection2_description}</p>
						<div className="line live">
							<Link href="/mint">{t.mint_live}</Link>
							<LiveAnimation/>
							{t.mint_live}
							<img src="/pic/arrow-right.svg" alt="Mint is live"/>
						</div>
						<div className="total">{numberFormat(15000)}</div>
						<p className="total_description">{t.mutants}</p>
						<div className="link">
							<a href="https://apenft.io/#/collection/TJjKSaj9UD9tQ27zvN6hpXiCwN2VsdNW7P" target="_blank" rel="noreferrer">
								{t.view_on_apenft}
								<img src="/pic/link-icon-2.svg" alt="View on APENFT MAYCT"/>
							</a>
						</div>
					</div>
				</div>
			</CenterBlock>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	margin-top: 120px;
	.list{
		max-width: 1020px;
		width: 100%;
		margin: -30px auto 0 auto;
		display: flex;
		justify-content: space-between;
		position: relative;
		@media only screen and (max-width: 820px) {
			flex-direction: column;
			justify-content: flex-start;
			margin: 40px 0 0 0;
			max-width: 100%;
		}
	}
	.item{
		width: calc(100% / 2 - 45px);
		padding: 30px 60px;
		background: #131323;
		border-radius: 36px;
		text-align: center;
		@media only screen and (max-width: 1024px) {
			width: calc(100% / 2 - 20px);
		}
		@media only screen and (max-width: 820px) {
			width: 100%;
			margin-bottom: 40px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		@media only screen and (max-width: 600px) {
			border-radius: 24px;
			padding: 30px 20px;
		}
	}
	.description{
		font-size: 24px;
		line-height: 140%;
		margin-top: 5px;
	}
	.sold{
		font-weight: bold;
		font-size: 32px;
		line-height: 140%;
		color: #3D3D55;
		margin-top: 25px;
	}
	.live{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 25px;
		font-size: 32px;
		line-height: 140%;
		font-weight: bold;
		position: relative;
		a{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			font-size: 0;
			z-index: 10;
		}
		img{
			margin-left: 30px;
		}
	}
	.live_animation{
		margin-right: 20px;
		top: -3px;
	}
	.line{
		border-bottom: 2px solid #3D3D57;
		padding-bottom: 30px;
	}
	.total{
		margin-top: 15px;
		font-size: 60px;
		line-height: 140%;
		font-weight: bold;
		font-family: "Halvar Breitschrift", sans-serif;
		color: #3D3D55;
		@media only screen and (max-width: 600px) {
			font-size: 30px;
			line-height: 140%;
		}
	}
	.total_description{
		font-size: 24px;
		line-height: 140%;
		color: #D0D0D3;
	}
	.link{
		margin-top: 40px;
		a{
			font-size: 24px;
			line-height: 140%;
			color: #000000;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			height: 80px;
			background: #FFFFFF;
			border-radius: 22px;
			@media only screen and (max-width: 600px) {
				font-size: 18px;
				line-height: 140%;
				height: 60px;
				border-radius: 16px;
			}
			img{
				margin-left: 30px;
				line-height: 0;
				position: relative;
				top: -2px;
			}
		}
	}
	.image{
		img{
			border-radius: 36px;
		}
	}
	.title{
		margin-top: 23px;
	}
`
